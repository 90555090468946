import { useEffect, useState } from 'react';
import { useStore } from '@nanostores/react';
import { $isReady, getTeaserBatch } from '@innhold/core/front/stores/blend-store';
import type { TeaserResponseType } from '@innhold/core/front/types';
import type { BlockLayoutType, LayoutTemplate } from '../../types';
import { flipLayoutItems, getLayoutSize, isFlipLayout } from '../../utils/layouts';
import { BatchLayout } from './BatchLayout';

type BatchProps = {
    layout: BlockLayoutType;
    offset: number;
    template: LayoutTemplate;
};

export const Batch = ({ layout, offset, template }: BatchProps) => {
    const isBlendReady = useStore($isReady);

    const [teasers, setTeasers] = useState<TeaserResponseType[]>([]);

    useEffect(() => {
        if (!isBlendReady) {
            return;
        }

        const fetchTeasersBatch = async () => {
            const batch = await getTeaserBatch(offset, getLayoutSize(layout));
            const batchTeasers = isFlipLayout(layout)
                ? flipLayoutItems(batch, layout)
                : batch;

            setTeasers(
                batchTeasers.map((teaser, indexInBatch) => ({
                    ...teaser,
                    meta: {
                        ...teaser.meta,
                        position: template[indexInBatch],
                    },
                }))
            );
        };

        fetchTeasersBatch();
    }, [isBlendReady, layout, offset, template]);

    if (!teasers.length) {
        return <div />;
    }

    return <BatchLayout teasers={teasers} layout={layout} />;
};
